<template>
  <div>
    <nav-bar v-if="!goodsId">
      <div class="edition-center" v-loading="loading">
        <div class="head-container">
          <div class="goods-image-carousel">
            <div class="big-image">
              <template v-if="bannerType.type == 'image'">
                <img :src="bannerType.url | filterPicture" />
              </template>
              <template v-if="bannerType.type == 'video'">
                <video
                  controls
                  controlslist="nodownload"
                  disablePictureInPicture
                  :src="bannerType.url | filterPicture"
                ></video>
              </template>
            </div>
            <div class="thumbnail-toolbar">
              <div class="guide" @click="guideLeft">
                <img src="@/assets/goods/left-arrow.png" alt="" />
              </div>
              <div class="thumbnail-container">
                <div class="thumbnail-list" :style="{ 'margin-left': step * 118 + 'px' }">
                  <div
                    :class="{ 'thumbnail-active': activeIndex === index }"
                    @click="switchBanners(item, index)"
                    v-for="(item, index) in detail.carouselUrl"
                    :key="index"
                  >
                    <template v-if="item.type == 'image'">
                      <img :src="item.url | filterPicture" alt="" />
                    </template>
                    <template v-if="item.type == 'video'">
                      <video :src="item.url | filterPicture"></video>
                    </template>
                  </div>
                </div>
              </div>
              <div class="guide" @click="guideRight">
                <img src="@/assets/goods/right-arrow.png" alt="" />
              </div>
            </div>
          </div>

          <div class="head-container_right">
            <div class="goods-name">
              <span class="goods-sn">{{ detail.sn }}</span>
              <span>{{ detail.name }}</span>
            </div>
            <div class="price-info relative">
              <div style="flex: 1">
                <div class="relative">
                  <template v-if="label">
                    <div
                      class="detailLabelBox flex align-center flex-wrap mr10"
                      v-for="(item, labelIndex) in detail.tag"
                      :key="labelIndex"
                    >
                      <div class="text mlr10">{{ item }}</div>
                    </div>
                  </template>
                </div>
                <div class="mt10 mb5 flex">
                  <div v-if="discount == 1" class="discount flex flex-center">
                    <span class="text">
                      {{ detail.promotionDiscount }}% de descuento
                    </span>
                  </div>
                  <div v-else>
                    <div
                      class="vip"
                      v-if="detail.deductionPrice && detail.participationDiscount"
                    >
                      <img src="@/assets/home/vip.png" alt="" />
                      <span>COP {{ detail.deductionPrice | filterPrice }}</span>
                    </div>
                  </div>
                </div>
                <div class="detailRate">
                  <el-rate v-model="detail.starRating" disabled :colors="colors" />
                </div>
                <div class="item-price flex">
                  <div v-if="discount == 1">
                    <span>COP {{ detail.promotionPrice | filterPrice }}</span>
                    <span class="discountPrice">
                      COP {{ detail.price | filterPrice }}
                    </span>
                  </div>
                  <div v-else>
                    <span>COP {{ detail.price | filterPrice }}</span>
                  </div>
                </div>
                <div class="mt-10px flex justify-between">
                  <span class="Salse"> Ventas total：{{ detail.salesCount }} </span>
                  <span class="Salse">
                    Ventas el mes pasado：{{ detail.monthlySales }}
                  </span>

                  <span class="Salse">Limita:{{ detail.purchaseLimit }}</span>
                </div>
              </div>
            </div>

            <div class="sku-container">
              <div>
                <!-- 发货仓库 -->
                <div class="container-title">Almacén de envío</div>
                <div class="flex flex-wrap mt-13px">
                  <div
                    v-for="(store, index) in storehouses"
                    :key="store.id"
                    class="store_item"
                    :class="[{ store_item_active: currentStorehouseIndex === index }]"
                    @click="handleSelectStorehouse(index)"
                  >
                    {{ store.name }}
                  </div>
                </div>
              </div>
              <div class="container-title mt-30px">Especificación</div>
              <div class="sku-list flex flex-wrap justify-between">
                <div
                  class="sku-item relative"
                  v-for="(item, index) in skusValues"
                  :key="index"
                >
                  <div class="skuImg flex">
                    <img
                      :src="item.imageUrls[0].url || item.imageUrls | filterPicture"
                      style="cursor: pointer; width: 100%; height: 100%"
                      @click="
                        $store.commit(
                          'SHOW_IMAGE_WALL',
                          item.imageUrls[0].url || item.imageUrls
                        )
                      "
                    />
                  </div>
                  <div class="ml40">
                    <div class="sku-name">
                      <span v-for="(specItem, specIndex) in item.spec" :key="specIndex">
                        {{ (specIndex ? '-' : '') + specItem.value }}
                      </span>
                    </div>
                    <div class="sku-stock-price">
                      <span>Inventario：{{ item.storeCount }}</span>
                      <br />
                      <br />
                      <span>Código de barras:{{ item.barCode }}</span>
                    </div>
                    <div class="sku-stock-price flex mt10 align-center">
                      <div class="">
                        <div>
                          <span v-if="discount === 1" class="price">
                            COP：{{ item.promotionPrice | filterPrice }}
                          </span>
                          <span v-else class="price">
                            COP：{{ item.price | filterPrice }}
                          </span>
                        </div>
                        <div v-if="discount === 1">
                          <span class="discountPrice">
                            COP：{{ item.price | filterPrice }}
                          </span>
                        </div>
                      </div>
                      <div class="ml15">
                        <el-input-number
                          v-model="item.addCount"
                          controls-position="right"
                          @change="handleChange($event, item)"
                          :min="0"
                          :max="item.storeCount || 0"
                          size="small"
                        ></el-input-number>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mt-10px flex items-center"
                v-if="!skusValues.filter((item) => item.storeCount).length"
              >
                <img
                  style="width: 15px; height: 18px"
                  src="@/assets/goods/un-storehouse.png"
                />
                <div class="out_of_stock ml-7px">
                  Actualmente no hay inventario en el almacén. Si necesita comprar, cambie
                  el almacén de envío.
                </div>
              </div>
              <div class="addCart">
                <el-button
                  type="success"
                  round
                  :disabled="disableAdd"
                  @click="confirmAdd"
                >
                  Añadir al carrito de compras
                </el-button>
              </div>
            </div>

            <div class="collect" @click="handelCollect">
              <img v-if="detail.collected" src="@/assets/goods/collect-active.png" />
              <img v-else src="@/assets/goods/collect.png" />
            </div>
          </div>
        </div>

        <div class="theme">
          <el-tabs type="card">
            <el-tab-pane label="Detalles del producto">
              <div class="goods-detail">
                <div v-for="(item, index) in detail.imageUrls" :key="index">
                  <template v-if="item.type == 'image'">
                    <img :src="item.url | filterPicture" alt="" />
                  </template>
                  <template v-if="item.type == 'video'">
                    <video
                      controls
                      controlslist="nodownload"
                      disablePictureInPicture
                      :src="item.url | filterPicture"
                    ></video>
                  </template>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Detalles de comentarios">
              <div class="goods-evaluate">
                <template v-if="commentList.length">
                  <div
                    class="evaluate-item"
                    v-for="(item, index) in commentList"
                    :key="index"
                  >
                    <div class="evaluate-content">
                      {{ item.content }}
                    </div>
                    <div class="evaluate-user">
                      <img
                        v-if="item.memberDetail.head"
                        :src="item.memberDetail.head | filterPicture"
                        alt=""
                      />
                      <img v-else src="@/assets/public/default-head.png" alt="" />
                      <span>{{ item.memberDetail.contactPerson }}</span>
                    </div>
                    <div class="evaluate-date">
                      {{ item.createdTime.split(' ')[0] }}
                    </div>
                  </div>
                </template>

                <div v-else class="empty-container">
                  <img src="@/assets/public/empty.png" alt="" />
                  <span>Nada todavía~</span>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="mt-15px" v-if="keyWords.goodsList">
          <div class="title-bar">
            <div class="title">
              Te podría gustar
              <div class="bar-color"></div>
            </div>
          </div>
          <div v-loading="keyWords.loading" element-loading-text="Espera un momento">
            <div class="goods-list">
              <product-card
                v-for="(item, index) in keyWords.goodsList"
                :key="index"
                :goods="item"
                @getGoodsId="handelCart"
                @toDetail="toDetail(item.id)"
              ></product-card>
            </div>
          </div>
        </div>

        <el-dialog
          :visible.sync="keyWords.dialogVisible"
          width="1400px"
          @close="hiddenDialog"
        >
          <goods-carts
            v-if="keyWords.cartsVisible"
            :goodsId="keyWords.goodsId"
          ></goods-carts>
        </el-dialog>
      </div>
    </nav-bar>

    <template v-else>
      <div class="sku-container" v-loading="loading">
        <div>
          <!-- 发货仓库 -->
          <div class="container-title">Almacén de envío</div>
          <div class="flex flex-wrap mt-13px">
            <div
              v-for="(store, index) in storehouses"
              :key="store.id"
              class="store_item"
              :class="[{ store_item_active: currentStorehouseIndex === index }]"
              @click="handleSelectStorehouse(index)"
            >
              {{ store.name }}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between mt-30px">
          <div class="container-title">Especificación</div>
        </div>
        <div class="sku-list flex flex-wrap mt-13px">
          <div
            class="sku-item mr-10px mb-10px"
            v-for="(item, index) in skusValues"
            :key="index"
          >
            <div class="skuImg">
              <img
                class="img"
                :src="item.imageUrls[0].url | filterPicture"
                alt=""
                @click="$store.commit('SHOW_IMAGE_WALL', item.imageUrls[0].url)"
              />
            </div>

            <div class="flex flex-column ml40">
              <div class="sku-name">
                <span v-for="(specItem, specIndex) in item.spec" :key="specIndex">
                  {{ (specIndex ? '-' : '') + specItem.value }}
                </span>
              </div>
              <div class="sku-stock-price">
                <span>Inventario：{{ item.storeCount }}</span>
              </div>
              <div class="sku-stock-price">
                <span>SKU：{{ item.id }}</span>
              </div>
              <div class="sku-stock-price">
                <span>Código de barras:{{ item.barCode }}</span>
              </div>
              <div class="sku-stock-price flex mt10 align-center">
                <div>
                  <div>
                    <span v-if="discount == 1" class="price">
                      COP：{{ item.promotionPrice | filterPrice }}
                    </span>
                    <span v-else class="price">
                      COP：{{ item.price | filterPrice }}
                    </span>
                  </div>
                  <div v-if="discount == 1">
                    <span class="discountPrice">
                      COP：{{ item.price | filterPrice }}
                    </span>
                  </div>
                </div>
                <!-- 购物车 -->
                <div class="ml-15px">
                  <el-input-number
                    v-model="item.addCount"
                    controls-position="right"
                    @change="handleChange($event, item)"
                    :min="0"
                    :max="inputNumber(item)"
                    size="small"
                  ></el-input-number>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-10px flex items-center" v-if="!skusValues.length">
          <img style="width: 15px; height: 18px" src="@/assets/goods/un-storehouse.png" />
          <div class="out_of_stock ml-7px">
            Actualmente no hay inventario en el almacén. Si necesita comprar, cambie el
            almacén de envío.
          </div>
        </div>
        <div class="addCart">
          <el-button type="success" round :disabled="disableAdd" @click="confirmAdd">
            Únete al carrito de la compra
          </el-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// @ts-nocheck

import navBar from '@/components/navBar/index.vue'
import utils from 'my-tools-library'
import productCard from '@/components/product/index.vue'
import goodsCarts from '@/views/goods/index.vue'

export default {
  props: ['goodsId'],
  name: 'goodsCarts',
  components: {
    navBar,
    goodsCarts,
    productCard,
  },

  data() {
    return {
      discount: false,
      label: false,
      step: 0,
      starRating: 4,
      colors: ['#FCBBBB', '#FCBBBB', '#FCBBBB'],
      num: 1,
      tabs: [{ name: 'Detalles del producto' }, { name: 'Detalles de comentarios' }],
      goodsIdTemp: '',
      detail: [],
      activeIndex: 0,
      bannerType: '',
      commentList: [],
      skusValues: [],
      cartList: [],
      disableAdd: false,
      loading: true,
      type: '',
      buyNum: '',
      inputNumberDisable: false,
      storehouses: [],
      currentStorehouse: {},
      currentStorehouseIndex: 0,
      keyWords: {
        loading: false,
        goodsList: [],
        dialogVisible: false,
        cartsVisible: false,
        goodsId: '',
        query: {
          pageNo: 1,
          pageSize: 100,
          goodsId: '',
        },
      },
    }
  },

  async created() {
    if (this.goodsId) {
      await this.getStorehouse()
      this.goodsIdTemp = this.goodsId
      this.type = 'shoppingCart'
      await this.getGoodsDetail()
    }
  },

  watch: {
    cartList: {
      handler(val) {
        this.buyNum = val.reduce((num, item) => num + item.nums, 0)
      },
      deep: true,
      immediate: true,
    },

    buyNum: {
      handler(newVal) {
        if (newVal) {
          this.skusValues.map((item) => {
            if (this.detail.purchaseLimit > 0) {
              let canBay =
                newVal >= this.detail.currentAvailableBuyNum ? +item.addCount : null
              this.$set(item, 'limit', canBay)
            }
          })
        }

        if (
          this.detail.purchaseLimit > 0 &&
          newVal >= this.detail.currentAvailableBuyNum
        ) {
          this.$forceUpdate()
          this.$message.info(
            `Límite de compra de ${this.detail.purchaseLimit} artículos en ${this.detail.purchaseLimitDay} días`
          )
        }
      },
      immediate: true,
      deep: true,
    },
    $route: {
      async handler(val) {
        console.log('🚀 ~ handler ~ val:', val)
        if (val.params.id) {
          this.goodsIdTemp = this.$route.params.id
          this.keyWords.goodsList = []
          await this.getStorehouse()
          await this.getGoodsDetail()
          await this.getGoodsComment()
        }
      },
      immediate: true,
    },
  },

  methods: {
    inputNumber(item) {
      if (item.limit === 0) {
        return item.limit
      }

      return item.limit || this.detail.currentAvailableBuyNum || item.storeCount || 0
    },
    guideLeft() {
      //如果margin-left为0则不再往右边移动
      if (this.step * 118 === 0) return
      this.step = this.step + 1
    },

    guideRight() {
      //
      //6指的是缩略图一共6张，4指的是可展示数量是4，因为点击右边的箭头step会逐减，所以用全部张数(负号) + 可展示张数 == 保证显示最后4张后列表不再向左移动
      if (this.step <= -this.detail.carouselUrl.length + 4) return
      this.step = this.step - 1
    },

    handleChange(event, item) {
      let length = this.cartList.length
      for (let i = 0; i < length; i++) {
        // 如果要添加的商品此前已经在传参列表中
        if (this.cartList[i].goodsSkuId === item.id) {
          this.cartList[i].nums = item.addCount
          this.cartList[i].radioTo = item.ratio * item.addCount
          // 如果步进器的值为空或0，则从购物车传参列表中将该条数据去掉
          if (!item.addCount) {
            this.cartList.splice(i, 1)
          }
          return
        }
      }

      //event为0或undefined时不允许加入购物车
      if (!event) return
      let form = {
        nums: item.addCount,
        goodsSkuId: item.id,
        radioTo: item.ratio * item.addCount,
        storageSettingId: item.storehouseId,
      }
      // 往传参列表添加新数据
      this.cartList.push(form)
    },

    //添加入购物车
    confirmAdd() {
      if (!this.cartList.length)
        return this.$message.warning('No ha seleccionado ningún producto')
      let buyCartRatio = this.cartList.reduce((pre, cur) => pre + cur.radioTo, 0)

      if (buyCartRatio < this.detail.orderRatio) {
        this.$message.warning(
          `La relación entre el número total de productos es mayor que${this.detail.orderRatio}`
        )
        return
      }

      this.disableAdd = true

      for (let i = 0; i < this.cartList.length; i++) {
        this.$axios.post('/cart/updateCart', this.cartList[i]).then(() => {
          if (i == this.cartList.length - 1) {
            this.$message.success('Añadido al carrito de compras con éxito!') //加入购物车成功
            this.disableAdd = false
          }
        })
      }
    },

    //获取商品详情
    getGoodsDetail() {
      // this.loading = true
      this.$axios.post('/goods/auth/detail/' + this.goodsIdTemp).then((res) => {
        this.detail = res.data.result
        this.detail.carouselUrl = this.detail.carouselUrl
          ? JSON.parse(this.detail.carouselUrl)
          : []
        this.bannerType = this.detail.carouselUrl[0]
        this.detail.imageUrls = utils.handleJsonImg(this.detail.imageUrls)
        this.skuVisible = true
        if (this.detail.tag) {
          this.detail.tag = res.data.result.tag.split('；')
          this.label = true
        } else {
          this.detail.tag = []
        }

        if (this.detail.hasPromotion) {
          this.discount = 1
        } else {
          this.discount = null
        }
        res.data.result.specsList.map((item) => {
          this.$set(item, 'addCount', 0)
          item.spec = JSON.parse(item.spec)
        })

        this.currentStorehouseIndex = 0
        setTimeout(() => {
          this.loading = false
        }, 1500)
        if (this.detail.loginMemberStorageSetting) {
          this.storehouses.forEach((item, index) => {
            if (item.id == this.detail.loginMemberStorageSetting.id) {
              this.currentStorehouseIndex = index
            }
          })
          this.filterSkuList()
        }
        this.keyWords.query.goodsId = this.detail.id
        setTimeout(() => {
          this.getKeyWordsProduct()
        }, 200)
      })
    },

    //获取商品评价
    getGoodsComment() {
      this.$axios
        .post('/goodsComment/auth/list', { goodsId: this.goodsIdTemp })
        .then((res) => {
          //
          this.commentList = res.data.result.rows
        })
    },

    //切换banner图
    switchBanners(item, index) {
      this.activeIndex = index
      this.bannerType = item
    },

    //添加收藏
    handelCollect() {
      let collectTemp = {
        objectType: 'GOODS',
        type: 'COLLECT',
        objectId: this.$route.params.id,
        whether: this.detail.collected ? false : true,
      }
      this.$axios.post('/operation/operation', collectTemp).then((res) => {
        this.detail.collected = this.detail.collected ? false : true
        if (this.detail.collected) {
          this.$message.success('Colección exitosa')
        } else {
          this.$message.success('Colección cancelar')
        }
      })
    },
    /**
     * @description: 获取仓库列表
     * @return {*}
     */
    async getStorehouse() {
      const params = {
        pageNo: 1,
        pageSize: 100,
      }
      try {
        const {
          data: {
            result: { rows },
          },
        } = await this.$axios.post('/storageSetting/findPageDetail', params)
        this.storehouses = rows
      } catch (error) {}
    },
    /**
     * @description: 选择仓库
     * @param {*} i
     * @return {*}
     */
    handleSelectStorehouse(i) {
      this.currentStorehouseIndex = i
      this.filterSkuList()
    },
    filterSkuList() {
      this.skusValues = []

      this.detail.specsList.forEach((item) => {
        item.skuStorageList.forEach((child) => {
          if (
            this.storehouses.length &&
            child.storageSettingId === this.storehouses[this.currentStorehouseIndex].id
          ) {
            const params = { ...item }
            params.storeCount = child.count
            params.storehouseId = child.storageSettingId
            this.skusValues.push(params)
          }
        })
      })
      this.skusValues.map((item) => {
        if (item.imageUrls) item.imageUrls = utils.handleJsonImg(item.imageUrls)
        return item
      })
    },
    async getKeyWordsProduct() {
      const {
        data: {
          result: { rows },
        },
      } = await this.$axios.post('/goods/findSameKeywordList', this.keyWords.query)

      this.keyWords.goodsList = rows
    },

    handelCart($event) {
      this.keyWords.goodsId = $event
      this.keyWords.cartsVisible = true
      this.keyWords.dialogVisible = true
    },
    toDetail(goodsId) {
      this.$router.push({
        path: '/goods',
        name: 'goods',
        params: { id: goodsId },
      })
    },
    hiddenDialog() {
      this.keyWords.dialogVisible = false
      this.keyWords.cartsVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.Salse {
  color: #979797;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.discountPrice {
  color: #979797;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-decoration: line-through;
}
</style>

<style lang="scss" scoped>
.detailRate {
  position: absolute;
  right: 10px;
  // top: 130px;
}

img {
  display: block;
}
.detailLabelBox {
  background-color: #d43a57;
  display: inline-block;
  width: auto;
  height: 18px;
  border-radius: 8px 0 16px 0;
  .text {
    color: #ffffff;
    font-size: 10px;
    line-height: 18px;
  }
}
.edition-center {
  width: 1200px;
  margin: 0 auto;
  .head-container {
    display: flex;
    margin-top: 20px;
    .goods-image-carousel {
      width: 540px;
      .big-image {
        width: 100%;
        height: 342px;
        img {
          width: 100%;
          height: 342px;
          object-fit: contain;
        }
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .thumbnail-toolbar {
        display: flex;
        margin-top: 20px;
        cursor: pointer;
        .guide {
          img {
            width: 26px;
          }
        }
        .thumbnail-container {
          flex: 1;
          margin: 0 5px;
          overflow: hidden;
          .thumbnail-list {
            white-space: nowrap;
            > div {
              display: inline-block;
              margin: 0 5px;
              vertical-align: middle;
              img,
              video {
                width: 108px;
                height: 64px;
                object-fit: cover;
                display: block;
              }

              &.thumbnail-active {
                border: 1px solid #8acf66;
              }
            }
          }
        }
      }
    }

    .head-container_right {
      margin-left: 40px;
      flex: 1;
      position: relative;
      .goods-name {
        font-size: 24px;
        color: #000;
        padding: 8px 0;
        margin-right: 20px;
        .goods-sn {
          display: inline-block;
          background: #fcbbbb;
          color: #ffffff;
          padding: 0 5px;
          border-radius: 6px;
          margin-right: 10px;
          font-size: 16px;
        }
        span {
          vertical-align: middle;
        }
      }

      .price-info {
        display: flex;
        align-items: center;
        background: #e7e7e7;
        padding: 10px 20px;
        border-radius: 5px;
        margin: 10px 0;
        .vip {
          padding-bottom: 5px;
          img {
            width: 39px;
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
          }
          span {
            font-size: 16px;
            color: #282828;
            vertical-align: middle;
          }
        }
        .item-price {
          color: #d43a57;
          font-size: 18px;
          span {
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle;
          }
        }
      }

      .sku-container {
        margin: 20px 0;
        .container-title {
          font-size: 14px;
          color: #979797;
        }
        .sku-list {
          .sku-item {
            display: inline-block;
            padding: 10px 10px 10px 10px;
            // margin: 10px 10px 0 0;
            margin-bottom: 10px;
            border: 1px solid #e7e7e7;
            border-radius: 8px;
            width: 305px;
            box-sizing: border-box;
            position: relative;
            > div {
              display: inline-block;
              vertical-align: middle;
            }
            .skuImg {
              position: absolute;
              top: 10px;
              left: 5px;
            }

            .sku-name {
              font-size: 16px;
              color: #282828;
              margin-bottom: 7px;
            }
            .sku-stock-price {
              font-size: 14px;
              color: #979797;
              margin-bottom: 5px;
            }
          }
          :nth-child(2n + 2) {
            margin-right: 0;
          }
        }

        .addCart {
          margin-top: 20px;
        }
      }

      .collect {
        position: absolute;
        top: 16px;
        right: 0;
        img {
          width: 25px;
        }
      }
    }
  }

  .theme {
    margin-top: 40px;

    ::v-deep .el-tabs__item.is-active {
      background: #45b035;
      color: #fff;
      height: 56px;
      line-height: 56px;
    }

    .goods-detail {
      img {
        width: 100%;
      }
      video {
        width: 100%;
        display: block;
        object-fit: cover;
      }
    }

    .goods-evaluate {
      .evaluate-item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e7e7e7;
        padding: 20px 0;
        line-height: 24px;
        .evaluate-content {
          font-size: 16px;
          color: #282828;
          flex: 5;
        }
        .evaluate-user {
          font-size: 14px;
          color: #282828;
          flex: 1;
          text-align: right;
          img {
            width: 24px;
            display: inline-block;
            margin-right: 8px;
            vertical-align: middle;
          }
          span {
            vertical-align: middle;
          }
        }
        .evaluate-date {
          font-size: 14px;
          color: #979797;
          flex: 1;
          text-align: right;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
//购物车sku样式
.sku-container {
  margin: 20px 0;
  .container-title {
    font-size: 16px;
    color: #000000;
  }
  .sku-list {
    // min-height: 100px;
    .sku-item {
      display: inline-block;
      padding: 10px 10px 10px 10px;
      // margin: 10px 10px 0 0;
      border: 1px solid #e7e7e7;
      border-radius: 8px;
      // width: 305px;
      box-sizing: border-box;
      position: relative;
      > div {
        display: inline-block;
        vertical-align: middle;
      }
      .skuImg {
        position: absolute;
        top: 10px;
        left: 5px;
      }

      .sku-name {
        font-size: 16px;
        color: #282828;
        margin-bottom: 7px;
      }
      .sku-stock-price {
        font-size: 14px;
        color: #979797;
        margin-bottom: 5px;
        .price {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #d43a57;
          line-height: 21px;
        }
      }
    }
  }

  .addCart {
    margin-top: 20px;
  }
}

.store_item {
  padding: 3px 10px;
  background: #f7fff7;
  border-radius: 5px;
  margin-right: 21px;
  cursor: pointer;
}

.store_item_active {
  border: 1px solid #8acf66;
}
.out_of_stock {
  height: 21px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #d43a57;
  line-height: 21px;
}

.title-bar {
  text-align: center;
  margin-bottom: 30px;
  .title {
    font-size: 28px;
    font-weight: bold;
    display: inline-block;
    .bar-color {
      width: 100%;
      height: 10px;
      background: #8acf66;
      margin-top: -15px;
    }
  }
}
.goods-list {
  min-height: 100px;
  > div {
    margin-top: 20px;
    margin-right: 20px;
  }
  :nth-child(4n + 4) {
    margin-right: 0;
  }
}
</style>
